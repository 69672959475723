@font-face {
  font-family: 'Museo';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Museo500-Regular.otf');
}

.App {
  text-align: center;
  font-family: 'Museo';
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
}

/* Main containers*/
.App-header {
  display: flex;
  flex: 2;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: calc(10px + 2vmin);
}
@media (min-width: 1px) and (max-width: 800px) {
  /* On iphone we want it to be smaller so subscribe form fits*/
  .App-header {
    flex: 1;
  }
  
}

.App-bottom {
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.App-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
.title {
  font-family: 'Museo';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(38px + 2vmin);
  color:rgb(27, 70, 54);
}
.subtitle {
  font-family: 'Museo';
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  font-size: calc(16px + 2vmin);
  color:rgb(27, 70, 54);

}
.subtitle-small {
  font-family: 'Museo';
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  font-size: calc(10px + 1vmin);
  color:rgb(27, 70, 54);

}

.App-subscribe {
  display: flex;
  flex: 1;
  width: 100%;
  font-family: 'Museo';
  display: flex;
  min-height: 80px;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  justify-content: flex-start;
  color:rgb(27, 70, 54);
}


.RedesLogo {
  margin-top: 300px;
  display: flex;
  flex: 1;
  width: 100%;
  font-family: 'Museo';
  display: flex;
  min-height: 80px;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  justify-content: center;
  color:rgb(27, 70, 54);
}
